"use strict";

export function showModal(this: any, modalName: string) {
    const showKey = 'show' + modalName
    this[showKey] = true;
    this.$nextTick(() => {
        this.$modal.show(modalName);
    });
}


export function hideModal(this: any, modalName: string) {
    const showKey = 'show' + modalName
    this[showKey] = false;
    this.$nextTick(() => {
        this.$modal.hide(modalName);
    });
}
