
import { Component, Vue, Prop } from "vue-property-decorator";
import { IMissionDB, StoreAction } from "@/types";
import { Feature, MissionCommand, MissionStatus, ROUTES } from "@/data";
import { showModal } from "@/helpers/callables";
import { Action, Getter, State } from "vuex-class";
import CancelMission from "@/components/modal/CancelMission.vue";
import EvaluationModal from "@/components/modal/EvaluationModal.vue";
import { toastError } from "@/helpers/commons";
import ExtendMission from "@/components/modal/ExtendMission.vue";
import clonedeep from "lodash.clonedeep";
import SendOrderToEvoliaModal from "@/components/modal/SendOrderToEvoliaModal.vue";
import i18n from "@/i18n";

@Component({
    name: "MissionAvailableCommands",
    components: { ExtendMission, EvaluationModal, CancelMission, SendOrderToEvoliaModal },
})
export default class MissionAvailableCommands extends Vue {
    @Prop() readonly mission!: IMissionDB;
    @Prop(String) readonly icon!: string;

    @Action("actions/sendCommandMission") sendCommandMission!: StoreAction;

    @Getter("isAgency") isAgency!: boolean;

    @State("currentAgency") currentAgency!: any;

    loading = false;
    showEvaluationModal = false;
    showCancelMission = false;
    showDeleteMission = false;
    showPresentMission = false;
    showExtendMission = false;
    showSendOrderToEvolia = false;
    openSendOrderToEvoliaAfterClose = false;
    isOpen = false;
    message = "";
    globalErrors: any = [];

    get displayableCommands() {
        return (
            this.mission?.availableCommands?.filter((command: string) => {
                if (this.mission.status === MissionStatus.ready && command === MissionCommand.sendOrderToEvolia) {
                    return false;
                }
                return command !== MissionCommand.addInterim && command !== MissionCommand.askAgency;
            }) || []
        );
    }

    async extendMission(data: any) {
        try {
            if (data.copy) {
                await this.sendCommand(MissionCommand.copy, { copyWorkers: true });
            } else {
                const c: any = clonedeep(data.period);
                if (c?.slots) {
                    Object.keys(c.slots).forEach((key: string) => {
                        c.slots[key].forEach((range: any) => {
                            if (typeof range.from === "string") {
                                range.from = parseInt(range.from.replace(":", ""));
                            }
                            if (typeof range.to === "string") {
                                range.to = parseInt(range.to.replace(":", ""));
                            }
                        });
                    });
                }
                await this.sendCommand(MissionCommand.extend, {
                    period: c,
                    workers: data.selectedWorkers,
                    description: data.description,
                    positions: data.positions,
                });
            }

            this.$modal.hide("ExtendMission");
        } catch (e) {
            console.log(e);
        }
    }

    async submitCancelMission(data: any) {
        try {
            const d: any = { cancelReason: data.reason };

            if (data.reason === "other") {
                d.cancelReasonOther = data.reasonText;
            }
            await this.sendCommand(MissionCommand.cancel, d);
            this.$modal.hide("CancelMission");
        } catch (e) {
            console.log(e);
        }
    }

    async submitEvaluation(data: any) {
        try {
            await this.sendCommand(MissionCommand.evaluate, {
                evaluations: data.notes.map((n: any) => ({
                    workerId: n._id,
                    note: n.note,
                    comment: n.comment,
                    blacklisted: n.blacklisted,
                })),
            });
            this.$modal.hide("EvaluationModal");
        } catch (e) {
            console.log(e);
        }
    }

    async submitDeleteMission() {
        try {
            await this.sendCommand(MissionCommand.delete);
            this.$modal.hide("DeleteMission");
        } catch (e) {
            console.log(e);
        }
    }

    async submitPresentMission() {
        try {
            await this.sendCommand(MissionCommand.present, { presentationMessage: this.message });
            this.message = "";
            this.$modal.hide("PresentMission");
        } catch (e) {
            console.log(e);
        }
    }

    async sendOrderToEvolia(cmi: string) {
        try {
            await this.sendCommand(MissionCommand.sendOrderToEvolia, { contractManagerIdentifier: cmi });
            this.$toast.open({
                message: i18n.t("modals.SendOrderToEvolia.success.sent") as string,
                type: "success",
                duration: 10000,
            });
            this.toggleSendOrderToEvolia();
        } catch (e) {
            console.error(e);
        }
    }

    toggleSendOrderToEvolia() {
        this.showSendOrderToEvolia = !this.showSendOrderToEvolia;
        if (this.showSendOrderToEvolia) showModal.call(this, "SendOrderToEvoliaModal");
        else this.$modal.hide("SendOrderToEvoliaModal");
    }

    closeOrderModal() {
        try {
            this.toggleSendOrderToEvolia();
            if (this.mission.status === MissionStatus.ready || this.mission.status === MissionStatus.proposed) {
                this.sendCommand(MissionCommand.complete);
                this.$toast.open({
                    message: i18n.t("modals.SendOrderToEvolia.success.closed") as string,
                    type: "success",
                    duration: 3000,
                });
            }
        } catch (error) {
            console.error(error);
            this.$toast.open({
                message: i18n.t("errors.Global.ServerError._default") as string,
                type: "error",
                duration: 10000,
            });
        }
    }

    closeOrderWithoutSending() {
        this.toggleSendOrderToEvolia();
    }

    async sendCommand(command: string, data?: any) {
        if (this.loading) return;
        this.loading = true;
        try {
            this.$toast.open({
                message: i18n.t("global.inProcess").toString(),
                type: "success",
                duration: 2500,
            });
            const result = await this.sendCommandMission({
                missionId: this.mission._id,
                command,
                data,
            });
            if (command === MissionCommand.agencyDecline && this.$route.name === ROUTES.APP.MISSION.DETAIL) {
                await this.$router.push({ name: ROUTES.APP.FOLLOWING._ROOT });
            } else if (command === MissionCommand.copy) {
                await this.$router.push({
                    name: ROUTES.APP.MISSIONCREATION,
                    query: { missionId: result._id, copy: "1" },
                });
            } else if (command === MissionCommand.extend) {
                await this.$router.push({ name: ROUTES.APP.MISSIONCREATION, query: { missionId: result._id } });
            } else {
                this.$emit("missionChanged", result);
            }
        } catch (e) {
            this.globalErrors = e._global;
            if (e._global[0]?.id?.includes("Mission")) {
                this.$toast.open({
                    message: i18n.t("errors." + e._global[0].id + "._default") as string,
                    type: "error",
                    duration: 10000,
                });
            } else {
                // @ts-ignore;
                this.$toast.open({
                    message: toastError(e),
                    type: "error",
                    duration: 10000,
                });
            }
            throw e;
        } finally {
            this.loading = false;
        }
    }

    displayCancelMission() {
        showModal.call(this, "CancelMission");
    }

    displayDeleteMission() {
        showModal.call(this, "DeleteMission");
    }

    displayPresentMission() {
        showModal.call(this, "PresentMission");
    }

    displayEvaluation() {
        showModal.call(this, "EvaluationModal");
    }

    displayExtendMission() {
        showModal.call(this, "ExtendMission");
    }

    doCommand(command: string) {
        switch (command) {
            case MissionCommand.cancel:
                this.displayCancelMission();
                break;
            case MissionCommand.update:
                if (this.$route.name !== ROUTES.APP.MISSIONCREATION) {
                    this.$router.push({ name: ROUTES.APP.MISSIONCREATION, query: { missionId: this.mission._id } });
                }
                break;
            case MissionCommand.delete:
                this.displayDeleteMission();
                break;
            case MissionCommand.present:
                this.displayPresentMission();
                break;
            case MissionCommand.evaluate:
                this.displayEvaluation();
                break;
            case MissionCommand.extend:
                this.displayExtendMission();
                break;
            case MissionCommand.complete:
                if (
                    this.currentAgency.features?.includes(Feature.EVOLIA_ORDERS) &&
                    this.mission.availableCommands.includes(MissionCommand.sendOrderToEvolia)
                ) {
                    this.openSendOrderToEvoliaAfterClose = true;
                    this.toggleSendOrderToEvolia();
                } else {
                    this.sendCommand(command);
                }
                break;
            case MissionCommand.sendOrderToEvolia:
                this.toggleSendOrderToEvolia();
                break;
            default:
                this.sendCommand(command);
                break;
        }
        this.isOpen = false;
    }

    label(command: string) {
        let l = this.$t("mission.availableCommands." + command);

        if (typeof l === "string") {
            return l;
        } else {
            return this.isAgency ? l.agency : l.company;
        }
    }
}
