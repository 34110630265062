
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {MissionCommand, ROUTES} from "@/data";
    import {IAgencyDB, IMissionDB, StoreAction} from "@/types";
    import {Action, Getter, State} from "vuex-class";
    import {showModal} from "@/helpers/callables";
    import CompanyEmailMissing from "@/components/modal/CompanyEmailMissing.vue";
    import TooMuchWorkerConfirm from "@/components/modal/TooMuchWorkerConfirm.vue";
    import {toastError} from "@/helpers/commons";
    import NewIcon from '../NewIcon.vue';

    @Component({
        name: "WorkerAvailableCommands",
        components: {TooMuchWorkerConfirm, CompanyEmailMissing, NewIcon}
    })
    export default class WorkerAvailableCommands extends Vue {
        @Prop() readonly mission!: IMissionDB;
        @Prop() readonly worker!: any;

        loading = false;
        showWorkerReject = false;
        showWorkerPresent = false
        showTooMuchWorkerConfirm = false;
        showCompanyEmailMissing = false;

        previousCommand = "";
        isOpen = false;
        reason = "";
        who = "";
        message = "";

        @Getter('isAgency') isAgency!: boolean;
        @Getter('userContext') userContext!: string;
        @State('currentAgency') currentAgency!: IAgencyDB;

        @Action('actions/sendCommandMission') sendCommandMission!:  StoreAction;

        get actionsCommands() {
            return this.worker?.availableCommands?.filter((command:string) => command !== "workerReproposed") || [];
        }

        get informationsCommands() {
            return this.worker?.availableCommands?.filter((command:string) => command === "workerReproposed") || [];
        }

        get whoOptions() {
            if (this.mission.realCompanyId) {
                const withEmails = this.mission.client?.collaborators?.filter((c:any) => c.email);

                if (withEmails?.length) {
                    return this.mission.client?.collaborators?.map((c:any) => ({label: c.firstname + " " + c.lastname + ' - ' + c.email, value: c.email}))
                }
            }
            if (this.mission.client?.contacts?.length) {
                const withEmails = this.mission.client.contacts.filter((c:any) => c.email);

                if (withEmails?.length) {
                    return withEmails.map((c:any) => ({label: c.firstname + " " + c.lastname + ' - ' + c.email, value: c.email}));
                }
            }
            if (this.mission.client?.email) {
                return [{label: this.mission.client.name, value: this.mission.client.email}];
            }
            return false;
        }

        async submitWorkerPresent() {
            try {
                await this.sendCommand(MissionCommand.workerPresent, {presentationMessage: this.message, presentationEmail: this.who});
                this.message = "";
                this.$modal.hide('WorkerPresent');
            } catch (e) {
                console.log(e);
            }
        }

        async submitWorkerReject() {
            try {
                await this.sendCommand(this.previousCommand, {reason: this.reason});
                this.reason = "";
                this.$modal.hide('WorkerReject')
            } catch (e) {
                console.log(e);
            }
        }

        async sendCommand(command:string, data?:any) {
            this.loading = true;
            try {
                if (!data) {
                    data = {
                        workerId: this.worker.workerId || this.worker._id,
                    };
                } else {
                    data = {
                        ...data,
                        workerId: this.worker.workerId || this.worker._id
                    }
                }
                const result = await this.sendCommandMission({
                    missionId: this.mission._id,
                    command,
                    data
                });

                if (command === "workerStartContract") {
                    this.$toast.open({
                        message: <string>this.$t('mission.alerts.contractGenerated'),
                        type: "success",
                        duration: 10000
                    });
                }

                this.$emit('command', result);
            } catch (e) {
                console.log(e);
                if (command === MissionCommand.workerPresent && e.errors && e.errors['email.missing']) {
                    showModal.call(this, 'CompanyEmailMissing');
                }
                if (command === MissionCommand.workerConfirm && e.errors && e.fullErr?.response?.data && e.fullErr?.response?.data[0]?.id === "position.full"){
                    showModal.call(this, 'TooMuchWorkerConfirm');
                }
                // @ts-ignore;
                this.$toast.open({
                    message: toastError(e),
                    type: "error",
                    duration: 10000
                });
            }
            this.loading = false;
        }

        displayWorkerPresent() {
            if (this.worker.presentationText && this.worker.presentationText[this.currentAgency._id]) {
                this.message = this.worker.presentationText[this.currentAgency._id];
            }
            showModal.call(this, 'WorkerPresent');
        }

        displayWorkerReject() {
            showModal.call(this, 'WorkerReject');
        }

        doCommand(command: string) {
            this.previousCommand = command;
            switch (command) {
                case MissionCommand.workerPresent:
                    this.displayWorkerPresent();
                    break;
                case MissionCommand.workerAgencyReject: // popin textarea
                case MissionCommand.workerCompanyReject: // popin textarea
                    this.displayWorkerReject();
                    break;
                default:
                    this.sendCommand(command);
                    break;
            }
            if(this.$refs.popover) {
                (<any>this.$refs.popover).hide();
            }
        }

        label(command: string) {
            let l = this.$t('mission.availableCommands.' + command);

            if (typeof l === "string") {
                return l;
            } else {
                return this.isAgency ? l.agency : l.company;
            }
        }

        goToCompanyEdit() {
            this.$modal.hide('CompanyEmailMissing');
            this.$router.push({name: ROUTES.APP.CLIENT._ROOT, params: {companyId: this.mission.client?._id || ''}, query: {edit: '1'}});
        }
    }
